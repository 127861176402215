/* editor.js用カスタムCSS */

/* image-tool:画像部分に対するスタイル */
.image-tool--withBackground .image-tool__image {
  background: #ffffff00;
}

/* image-tool:キャプション部分を非表示にする */
.image-tool__caption {
  display: none;
}

/* エディタ下部パディング設定 */
.codex-editor__redactor {
  padding-bottom: 80px;
}

/* コンテンツブロックとツールバーの最大幅調整 */
.ce-block__content,
.ce-toolbar__content {
  max-width: calc(100% - 80px) !important;
}

/* エディタ内の各ブロックの最大幅を100%に設定 */
.cdx-block {
  max-width: 100% !important;
}
